import { Container } from '@material-ui/core'
import { graphql } from 'gatsby'
import React from 'react'
import NavigationBar from '../components/navigation-bar/navigation-bar'
import SingleArticle from '../components/single-article/single-article'

interface Node {
    id: string
    excerpt: string
    fields: {
        slug: string
        sourceName: string
        path: string
    }
    frontmatter: {
        title: string
        subtitle?: string
        date?: string
    }
}

interface Props {
    data: {
        allMarkdownRemark: {
            nodes?: Node[]
        }
    }
}

export default function IndexPage({ data }: Props) {
    NavigationBar.useSetTitle('Personal Website')
    // const nodes = sortBy(data.allMarkdownRemark.nodes, x => x.frontmatter.date)
    const nodes = data.allMarkdownRemark.nodes
    // console.log(nodes)
    
    return <Container
        maxWidth='sm'
        style={{
            paddingTop: 32,
            paddingBottom: 32,
        }}
        children={nodes?.map(n => <SingleArticle
            key={n.id}
            title={n.frontmatter.title}
            content={n.excerpt}
            publishedOn={n.frontmatter.date}
            readMorePath={n.fields.path}
            style={{
                marginBottom: 80
            }}
        />)}
    />
}

export const pageQuery = graphql`query indexData {
    site {
        siteMetadata { title }
    }
    allMarkdownRemark(
        filter: {
            fields: {sourceName: {eq: "posts"}}
            frontmatter: {status: {eq: "published"}}
        },
        limit: 100
        sort: {fields: frontmatter___date, order: DESC}
    ) {
        nodes {
            id
            excerpt(format: HTML)
            fields {
                path
                sourceName
                slug
            }
            frontmatter {
                title
                subtitle
                date
            }
        }
    }
}`
